import { observable } from 'mobx';

import { ServerRouteHelper } from 'app/helpers';
import EmailDomainsNonexclusiveModel from 'app/models/EmailDomainsNonexclusiveModel';
import { ModelList } from 'app/models/ModelList';
import Store from 'app/stores/Store';

export class EmailDomainsNonexclusiveStore extends Store<EmailDomainsNonexclusiveModel> {
  @observable nonExclusiveOrgs = new ModelList<EmailDomainsNonexclusiveModel>(
    EmailDomainsNonexclusiveModel
  );

  constructor() {
    super();
    EmailDomainsNonexclusiveModel._store = this;
  }

  async loadNonExclusiveOrgs(): Promise<void> {
    const url = ServerRouteHelper.api.organizations.nonExclusiveOrgs();

    await this.nonExclusiveOrgs.load(url);
  }
}

export default EmailDomainsNonexclusiveStore;
