import { observable } from 'mobx';

import Model from 'app/models/Model';
import EmailDomainsNonexclusiveStore from 'app/stores/EmailDomainsNonexclusiveStore';

import EmailDomainModel from './EmailDomainModel';
import OrganizationModel from './OrganizationModel';

export class EmailDomainsNonexclusiveModel extends Model {
  static _store: EmailDomainsNonexclusiveStore;

  readonly id: number;
  @observable organization: OrganizationModel;
  @observable email_domain: EmailDomainModel;
}

export default EmailDomainsNonexclusiveModel;
